// Version: 1.0
// src/js/main.js
import $ from 'jquery';
import 'bootstrap';

window.jquery = window.jQuery = $;

$(document).ready(function() {

  function setPageTitleAndMetaDescriptions() {
    var title = i18n("meta_title");
    var description = i18n("meta_description");
    var keywords = i18n("meta_keywords");
    var ogTitle = i18n("meta_og_title");
    var ogDescription = i18n("meta_og_description");
    var ogUrl = "https://ato.ppltrainer.at/";
    var ogType = "website";
    document.getElementsByTagName('meta')["keywords"].content = keywords;
    document.getElementsByTagName('meta')["description"].content = description;
    document.getElementsByTagName('meta')["og:title"].content = ogTitle;
    document.getElementsByTagName('meta')["og:description"].content = ogDescription;
    document.getElementsByTagName('meta')["og:url"].content = ogUrl;
    document.getElementsByTagName('meta')["og:type"].content = ogType;
    document.title = title;
  }

  // Sprachthematik
  function getURLParameter(value) {
    var urlparameter = URLSearchParams && new URLSearchParams(window.location.search);
    return urlparameter.get(value);
  }

  function getLanguageBasedOnDomain() {
    var val = location.hostname.split('.').pop();
    if (val === "localhost") return "at";
    return val;
  }

  var preferredLanguage = localStorage.getItem("preferredLanguage");
  var lang = preferredLanguage || getLanguageBasedOnDomain() || "en";
  var urlparameter = getURLParameter('lang');
  if (urlparameter) {
    lang = urlparameter;
  }
  if (lang.indexOf("de") >= 0 || lang.indexOf("at") >= 0 || lang.indexOf("ch") >= 0) {
    lang = "de";
  } else if (lang.indexOf("en") >= 0) {
    lang = "en";
  } else if (lang.indexOf("es") >= 0) {
    lang = "es";
  } else if (lang.indexOf("nl") >= 0 || lang.indexOf("be") >= 0) {
    lang = "nl";
  } else if (lang.indexOf("ro") >= 0) {
    lang = "ro";
  } else if (lang.indexOf("cs") >= 0 || lang.indexOf("cz") >= 0) {
    lang = "cs";
  } else if (lang.indexOf("sk") >= 0) {
    lang = "sk";
  } else if (lang.indexOf("sl") >= 0) {
    lang = "sl";
  } else if (lang.indexOf("fr") >= 0) {
    lang = "fr";
  } else if (lang.indexOf("is") >= 0) {
    lang = "is";
  } else if (lang.indexOf("fi") >= 0) {
    lang = "fi";
  }

  // Setze die Sprache
  setLanguage(lang);
  // Initialy set page title based on domain
  setPageTitleAndMetaDescriptions();

  $('.preloader').fadeOut(500);

  // Dropdown-Menü manuell steuern
  $('#dropdownMenuLink').on('click', function(event) {
    event.preventDefault();
    var $dropdownMenu = $('#change-language-placeholder');
    if ($dropdownMenu.hasClass('show')) {
      $dropdownMenu.removeClass('show');
      $(this).attr('aria-expanded', 'false');
    } else {
      $dropdownMenu.addClass('show');
      $(this).attr('aria-expanded', 'true');
    }
  });

  // Schließen des Dropdown-Menüs, wenn außerhalb geklickt wird
  $(document).on('click', function(event) {
    if (!$(event.target).closest('#dropdownMenuLink').length) {
      var $dropdownMenu = $('#change-language-placeholder');
      if ($dropdownMenu.hasClass('show')) {
        $dropdownMenu.removeClass('show');
        $('#dropdownMenuLink').attr('aria-expanded', 'false');
      }
    }
  });

  $('.show-details-btn').on('click', function (e) {
    e.preventDefault();
    var target = $(this).attr('href');
    $('html, body').animate({
      scrollTop: $(target).offset().top
    }, 600, 'linear');
  });

  // ScrollUp
  $.scrollUp({
    scrollText: '<i class="icofont-swoosh-up"></i>',
    easingType: 'linear',
    scrollSpeed: 900,
    animation: 'fade'
  });

  // Modal Event-Listener
  if ($('#yt-video').length) {

    $('#yt-video').on('hidden.bs.modal', function () {
      // Reset the video URL when the modal is closed
      $('.yt-video').attr("src", "");
      $('.video-container').hide();
      // Entferne das padding-right vom body
      $('body').css('padding-right', '0');
    });

    $('#yt-video').on('show.bs.modal', function () {
      var videoSrc = "https://www.youtube.com/embed/PA3rVvCTLFg?autoplay=1";
      $('.yt-video').attr('src', videoSrc);
      $('.video-container').show();
    });
  } else {
  }

  // Accourdian Plus Minus icon
  $(".collapse.show").each(function(){
    $(this).prev(".card-header").find(".my").addClass("icofont-minus").removeClass("icofont-plus");
  });
  // Toggle plus minus icon on show hide of collapse element
  $(".collapse").on('show.bs.collapse', function(){
    $('#accordionEx').find(".my").addClass("icofont-plus");
    $('#accordionEx').find(".card.active").removeClass("active");
    $(this).prev(".card-header").find(".my").removeClass("icofont-plus").addClass("icofont-minus");
    $(this).closest(".card").addClass("active");
  });

  // Sticky Header
  $(function() {
    var header = $("#header"),
        yOffset = 0,
        triggerPoint = 200;
    $(window).on('scroll', function() {
      yOffset = $(window).scrollTop();
      if (yOffset >= triggerPoint) {
        header.removeClass("animated cssanimation");
        header.addClass("navbar-fixed-top sticky cssanimation animated fadeInTop");
      } else {
        header.removeClass("navbar-fixed-top sticky cssanimation animated fadeInTop");
        header.addClass("animated cssanimation");
      }
    });
  });

  // Preloader
  $(window).on('load',function(){
    jQuery(".preloader-wrap").fadeOut(500);

    var d = new Date();
    var year = d.getFullYear();
    jQuery("#year").text(year);
  });

  // Sprache ändern
  $('#change-language-placeholder a').on("click", function() {
    var lang = $(this).attr("lang");
    var $body = $('body');
    $body.attr('class', $body.get(0).className.replace(/\lang-\S+/g, ''));
    $body.addClass("lang-" + lang);
    window.setLanguage(lang);
  });

  function hideRequestStatus(notificationContainer) {
    $(notificationContainer).hide();
    $(notificationContainer + "-hinweis").show();
    $('#requestDemoModal').modal('hide');
    $('.modal-backdrop').fadeOut(300, function() {
      $(this).remove();
    });
  }

  // Formular-Submit
  $('.form-submit').on("submit", function(event) {
    event.preventDefault();
    var formClass = $(this).attr('data-form-id') || $(this).attr('class').split(' ')[1];
    const notificationContainer = '.submit-' + formClass + '-success-container';

    var apiUrl = "https://api.ppltrainer.at/form/ato/ato_request.php";

    // needs for recaptcha ready
    grecaptcha.ready(function() {
      // do request for recaptcha token
      // response is promise with passed token
      grecaptcha.execute('6LeUgYEdAAAAAENgImHwucdJV5VzLjbCun6S16mU', {action: "ato_request"}).then(function(token) {
        // add token to form
        $('.' + formClass).prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
        $.post(apiUrl, {
          name: $('.' + formClass + ' [name="name"]').val(),
          email: $('.' + formClass + ' [name="email"]').val(),
          comment: $('.' + formClass + ' [name="comment"]').val(),
          token: token
        }, function(result) {
          if(result.success) {
            $('.form-submit input').val("");
            $('.submit-' + formClass + '-success-container').show();
            $('.submit-' + formClass + '-success-container-hinweis').hide();
            setTimeout(function() {
              hideRequestStatus(notificationContainer);
            }, 5000);
          }
        });
      });
    });
  });

});

// fallback for loading video in background
setTimeout(function() {
  $('.preloader').fadeOut(500);
}, 7000);